import "./index.scss"
import { navigate, Link } from "gatsby"
import _ from "lodash"
import React, { useState, useEffect, useReducer } from "react"
import { MdAddAlert, MdKeyboardArrowLeft, MdWarning } from "react-icons/md"
import { useForm } from "react-form"
import {
  getOrganization,
  getOrganizations,
  updateOrganizations,
} from "../../services/organizations"
import {
  getBranches,
  getBranch,
  updateBranches,
  createBranches,
} from "../../services/branch"
import { getUser, updateUsers, createUsers } from "../../services/user"
import auth from "../../services/auth"
import { getRoles } from "../../services/roles"
import { getUsers } from "../../services/user"
import WebsiteLayout from "../../components/layout/inner-layout/inner-layout"
import TextField from "../../components/common/form/text-field/text-field"
import ConfirmNavLink from "../../components/common/confirm-nav-link/confirm-nav-link"
import Checkbox from "../../components/common/form/check-box/check-box"
import Button from "../../components/common/button/button"
import AlertModal from "../../components/common/alert-modal/alert-modal"
import ModalWrapper from "../../components/common/modal/modal-wrapper/modal-wrapper"
import SuggestedKeywords from "../../components/common/suggested-keywords/suggested-keywords.module.scss"
import { useNavigate } from "@reach/router"
// import SignUpUser from "../sign-up-user"
import validator from "validator"
import styles from "../../components/common/suggested-keywords/suggested-keywords.module.scss"
import { NotificationManager } from "react-notifications"
import { NotificationContainer } from "react-notifications"
import "react-notifications/lib/notifications.css"
import ModalWindow from "../../components/common/modal-window/modal-window"

const EditBranch = ({location}) => {
  let authObj = auth.getAuth()
  const [uniqueID, setUniqueID] = useState(0)
  const [organizations, setOrganizations] = useState([])
  const [branches, setBranches] = useState([])
  const [users, setUsers] = useState([])
  const [submitAttempted, setSubmitAttempted] = useState()
  const [filteredResults, setFilteredResults] = useState([])
  const [searchInput, setSearchInput] = useState("")
  const [modalOpen, setModalOpen] = useState(false)
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [editUser, setEditUser] = useState([])
  const [selectedData, setSelectedData] = useState({
    id: null,
    name: "",
    admins: "",
  })
  const [roles, setRoles] = useState([])

  const [signUpCode, setSignUpCode] = useState()

  const useContext = React.createContext()
  const { data, editData } = useContext

  const currentParamOid = new URLSearchParams(location.search).get("user.id")

  useEffect(() => {
    getBranch(currentParamOid).then(branch => {
      setSelectedData(branch)
      setSignUpCode(branch.signupcode)
      formInstance.setValues({ moderate: branch.moderate })
    })

    getRoles().then(roles => {
      console.log("Roles", roles)
      setRoles(roles)
    })

    getUsers(true).then(users => {
      console.log("Users", users)
      const usr = users.filter(e => {
        if (e.branch && e.branch.id === currentParamOid) {
          console.log("branch", currentParamOid)
          return e
        }
      })
      setUsers(usr)
    })
  }, [])

  // const handleOnChange = (dataKey, val) => {
  //   setSelectedData({
  //     ...selectedData,
  //     [dataKey]: val,
  //   })
  // }

  const roleHandler = (usrObj, userRole) => {
    let obj = {
      role: roles.find(element => element.role.id === userRole),
      userId: usrObj._id,
    }
    let result = editUser.concat(obj)
    setEditUser(result)
  }

  const confirmHandler = modalBtnType => {
    
    if (modalBtnType === "confirm") {
      editUser.forEach(eUser => {
        console.log("user", eUser)
        // confirm btn click
        let obj = {}
        if (eUser.role) {
          obj.role = eUser.role.role.id
        } else {
        obj.blocked = eUser.blocked
          if (modalBtnType === "cancel") {
            obj.blocked = !eUser.blocked
          }
        }
        updateUsers(eUser.userId, obj).then(usr => {
          setUsers(pre =>
            pre.map(e => {
              if (e._id == eUser.userId) {
                return usr
              } else {
                return e
              }
            })
          )
        })
      })
      setEditUser([])
    } 
    setModalOpen(false)
  }

  const submitForm = () => {
    setModalOpen(true)
  }

  const resetForm = () => {
    setUniqueID(uniqueID + 1)
    setEditUser([])
  }

  const activeHandler = (usrObj, type) => {
    let obj = {
      blocked: true,
      userId: usrObj._id,
    }
    if (type === "active") {
      obj.blocked = false
    }
    let result = editUser.concat(obj)
    setEditUser(result)
  }

  // const onGenerateClick = () => {
  //   const sCode = Math.floor(1000 + Math.random() * 9000)
  //   setSignUpCode(sCode)
  // }

  const searchItems = searchValue => {
    setSearchInput(searchValue)
    if (searchInput !== "") {
      const filteredData = users.filter(item => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      })
      setFilteredResults(filteredData)
    } else {
      setFilteredResults(users)
    }
  }

  const formInstance = useForm({
    debugForm: false,
    onSubmit: values => {
      var updatedObj = null
      updatedObj = {
        name: values.name,
        ...values,
        signupcode: signUpCode,
      }

      updateBranches(currentParamOid, updatedObj).then(branches => {
        setBranches(branches)
      })

      NotificationManager.success(
        "Data has been saved Successfully!",
        "Successful!",
        2000
      )
    },

    validate: (values, instance) => {
      setSubmitAttempted(false)

      if (!values.name) {
        return true //  "name is required"
      }
    },
  })

  const onBackClick = async () => {
    await navigate("/manage-companies")
  }

  const { Form, meta, pushFieldValue } = formInstance

  return (
    <WebsiteLayout key={uniqueID}>
      <div className="addModalShow">
        <div className="modal-body">
          <div className="pointer">
            <span style={{ cursor: "pointer" }}>
              <a id="return-to-list" onClick={onBackClick} className="return">
                <MdKeyboardArrowLeft /><p>Go Back</p>
              </a>
            </span>
          </div>
          <br />
          <Form>
            <div className="textarea">
              <TextField
                label="Name"
                field="name"
                type="text"
                value={selectedData.name}
                disabled
                mandatory
              />
            </div>

            <br />

            <table style={{ width: "100%" }} className="company-list">
              <thead>
                <h5>Manage Users</h5>

                <br />
                <br />
                <div className="searchbar" style={{ width: "450px" }}>
                  <TextField
                    type="text"
                    placeholder="Search for user"
                    prependIcon="MdSearch"
                    field="searchText"
                    onChange={e => searchItems(e.target.value)}
                  ></TextField>
                </div>

                <tr>
                  <th style={{ width: "10%" }}>Users</th>
                  <th style={{ width: "10%" }}>Email</th>
                  <th style={{ width: "50%" }}>Role</th>
                  <th style={{ width: "30%" }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {searchInput.length > 1
                  ? filteredResults.map((user, index) => {
                      return (
                        <tr key={index}>
                          <td>{user.username}</td>
                          <td>{user.email}</td>

                          <td>
                            <div className="dropdownbtn">
                              <select
                                className={`${
                                  authObj?.user?.role?.type === user?.role?.type
                                    ? "aa-disabled"
                                    : ""
                                }`}
                                defaultValue={user.role.id}
                                onChange={e =>
                                  roleHandler(user, e.target.value)
                                }
                              >
                                {roles.map((role, index) => (
                                  <option value={role?.role?.id} id={role?._id} key={index}>
                                    {role?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </td>
                          <td>    
                            <div className="dropdown">
                              <select>
                                <option value="active">Active</option>
                                <option value="deactivated">Deactivated</option>
                              </select>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  : users.map((user, index) => (
                      <tr key={index}>
                        <td>
                          <span>{user?.username}</span>
                        </td>
                        <td>{user?.email}</td>

                        <td>
                          <div className="dropdownbtn">
                            <select
                              className={`${
                                authObj?.user?.role?.type === user?.role?.type
                                  ? "aa-disabled"
                                  : ""
                              }`}
                              defaultValue={user.role.id}
                              onChange={e => roleHandler(user, e.target.value)}
                            >
                              {roles.map((role, index) => (
                                <option value={role?.role?.id} id={role?._id} key={index}>
                                  {role?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </td>
                        <td>
                          <div className="dropdown">
                            <select
                              className={`${
                                authObj?.user?.role?.type === user?.role?.type
                                  ? "aa-disabled"
                                  : ""
                              }`}
                              defaultValue={!user.blocked ? "active" : "deactivated"}
                              onChange={e => activeHandler(user, e.target.value)}
                            >
                              <option id="active" value="active">
                                Active
                              </option>

                              <option id="deactivated" value="deactivated">
                                Deactivated
                              </option>
                            </select>
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </Form>
          <div className="buttonRow">
            <span>
              <Button
                id="cancel-media"
                label="Cancel"
                btnStyle="danger"
                onClick={e => resetForm()} 
              />
            </span>
            <span style={{ marginLeft: "15px" }}>
              <Button 
                id="save-media"
                label="Submit"
                onClick={e => submitForm()} 
              />
              </span>
            </div>
        </div>
      </div>
      {modalOpen && (
        <ModalWindow setModalOpen2={setModalOpen}>
          <div className="warning">
            <MdWarning />
          </div>
          <div className="confirmtext">
            <h2>Confirmation!</h2>
            <p>Do you really want to continue ?</p>
          </div>
          <br />
          <br />

          <div className="modalcancel">
            <Button
              label="Cancel"
              btnStyle="secondary"
              onClick={() => confirmHandler("cancel")}
            ></Button>
            <span style={{ marginRight: "10px" }}>
              <br />
            </span>

            <Button label="Confirm" onClick={() => confirmHandler("confirm")} />
          </div>
        </ModalWindow>
      )}
    </WebsiteLayout>
  )
}

export default EditBranch
